<template>
  <div class="hospitalInfo_container">
    <top style="padding: 0.2rem 0"></top>
    <div class="hospitalInfo_content_block">
      <div class="hospitalInfo_content">
        <div class="hospitalInfo_head">被变更人信息</div>
        <div class="hospitalInfo_main">
          <div
            class="hospitalInfo_item"
            v-for="(val, index) in dataChangedList"
            :key="index"
          >
            <div style="flex: 1 0 0">
              <div class="hospitalInfo_item_key">{{ val.label }}</div>
              <div class="hospitalInfo_item_value">
                <el-input
                  class="hospitalInfo_item_value_input"
                  v-if="val.contentType == 'input'"
                  v-model.trim="changed_person[val.key]"
                  :placeholder="`请输入${val.label}`"
                  :disabled="val.key != 'phone'"
                  @input="changeInput(val, 'changed_person')"
                  show-word-limit
                  :maxlength="
                    val.key == 'phone' || val.key == 'sale_phone' ? 11 : ''
                  "
                ></el-input>
                <el-input-number
                  :disabled="true"
                  v-if="val.contentType == 'inputNumber'"
                  v-model="changed_person[val.key]"
                  placeholder="0"
                ></el-input-number>

                <el-select
                  disabled
                  v-if="val.contentType == 'select'"
                  v-model="changed_person[val.key]"
                  :placeholder="`请选择${val.label}`"
                  @change="changeValue(val, 'changed_person')"
                  :allow-create="val.key == 'hospital' ? false : true"
                  :filterable="val.method"
                >
                  <el-option
                    v-for="(item, index) in getSelectOption(
                      val.key,
                      'changed_person'
                    )"
                    :key="index"
                    :label="item.label || item"
                    :value="item.id || item.label || item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hospitalInfo_content">
        <div class="hospitalInfo_head">变更人信息</div>
        <div class="hospitalInfo_main">
          <div
            class="hospitalInfo_item"
            v-for="(val, index) in dataChangeList"
            :key="index"
          >
            <div style="flex: 1 0 0">
              <div class="hospitalInfo_item_key">
                {{ val.label }}
              </div>
              <div class="hospitalInfo_item_value">
                <el-input
                  class="hospitalInfo_item_value_input"
                  v-if="val.contentType == 'input'"
                  v-model.trim="change_person[val.key]"
                  :placeholder="`请输入${val.label}`"
                  :disabled="val.key == 'hospital_code'"
                  @input="changeInput(val, 'change_person')"
                  show-word-limit
                  :maxlength="
                    val.key == 'phone' || val.key == 'sale_phone' ? 11 : ''
                  "
                ></el-input>

                <el-input-number
                  :disabled="!change_person['phone'] ? true : false"
                  v-if="val.contentType == 'inputNumber'"
                  v-model="change_person[val.key]"
                  placeholder="0"
                  :max="changedMax[val.key] || 0"
                  :min="changeMin[val.key] || 0"
                  @change="changeNumber($event, val.key)"
                ></el-input-number>
                <el-select
                  v-if="val.contentType == 'select'"
                  v-model="change_person[val.key]"
                  :placeholder="`请选择${val.label}`"
                  @change="changeValue(val, 'change_person')"
                  :allow-create="val.key == 'hospital' ? false : true"
                  :filterable="val.method"
                >
                  <el-option
                    v-for="(item, index) in getSelectOption(
                      val.key,
                      'change_person'
                    )"
                    :key="index"
                    :label="item.label || item"
                    :value="item.id || item.label || item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hospitalInfo_foot">
      <el-button class="submit" type="primary" @click="doCommit()">
        <span>提交</span>
      </el-button>
    </div>
  </div>
</template>
<script>
import top from "./top.vue";

export default {
  name: "hospitalInfo",
  components: { top },

  data() {
    return {
      config: {
        AreaList: [],
        change_personRMMList: [],
        change_personRSMList: [],
        change_personDSMList: [],
        changed_personRMMList: [],
        changed_personRSMList: [],
        changed_personDSMList: [],
        TaList: ["LC", "PC", "MM", "BC", "Pan tumor", "Other"],
        hospitalList: [],
        departmentList: [],
        citys: [],
        count: [0, 1, 2, 3],
      },
      dataChangedList: [], //被
      dataChangeList: [],
      oldchanged_person: {},
      oldchange_person: {},
      changed_person: {
        RMM: "",
        area: "",
        RSM: "",
        DSM: "",
        sale_name: "",
        sale_phone: "",
        name: "",
        code: "",
        province: "",
        city: "",
        hospital: "",
        hospital_code: "",
        department: "",
        phone: "",
        Ta: "",
        can_cohosts_count: 0,
        can_guest_count: 0,
        can_speaker_count: 0,
      },
      change_person: {
        RMM: "",
        area: "",
        RSM: "",
        DSM: "",
        sale_name: "",
        sale_phone: "",
        name: "",
        code: "",
        province: "",
        city: "",
        hospital: "",
        hospital_code: "",
        department: "",
        phone: "",
        Ta: "",
        cohosts_count: 0,
        guest_count: 0,
        speaker_count: 0,
      },
      changeMin: {
        cohosts_count: 0,
        guest_count: 0,
        speaker_count: 0,
      },
      changedMax: {
        cohosts_count: 0,
        guest_count: 0,
        speaker_count: 0,
      },
      loading: false,
      dict: {
        phone: {
          label: "医生电话",
          type: "input",
        },
        name: {
          label: "医生姓名",
          type: "input",
        },
        // can_cohosts_count: {
        //   // label: "主席权益",
        //   label: "主席剩余权益",
        //   type: "inputNumber",
        // },
        can_speaker_count: {
          label: "讲者剩余权益",
          type: "inputNumber",
        },
        // can_guest_count: {
        //   label: "讨论剩余权益",
        //   type: "inputNumber",
        // },
        area: {
          label: "区域",
          type: "select",
          method: false,
        },
        RMM: {
          label: "RMM",
          type: "select",
          method: false,
        },

        RSM: {
          label: "RSM（大区经理）",
          type: "select",
          method: false,
        },
        DSM: {
          label: "DSM（地区经理）",
          type: "select",
          method: false,
        },
        sale_name: {
          label: "志愿者姓名",
          type: "input",
        },
        sale_phone: {
          label: "志愿者电话",
          type: "input",
        },
        code: {
          label: "HCP医生编码",
          type: "input",
        },
        province: {
          label: "省份",
          type: "select",
          method: false,
        },
        city: {
          label: "城市",
          type: "select",
          method: false,
        },
        hospital: {
          label: "医院",
          type: "select",
          method: true,
        },
        hospital_code: {
          label: "医院code",
          type: "input",
        },
        department: {
          label: "科室",
          type: "select",
          method: true,
        },
        Ta: {
          label: "主TA",
          type: "select",
        },
      },
      oldNum: {
        cohosts_count: 0,
        guest_count: 0,
        speaker_count: 0,
      },
      getInfoTimer: null,
    };
  },
  async created() {
    this.config.citys = await this.$tools.getCityConfig();
    this.$tools.removeTouch();
  },
  mounted() {
    document.getElementsByTagName("title")[0].innerHTML = " ";
    this.initData("changed_person");
  },
  computed: {
    provinces() {
      return this.config.citys.map((item) => item.name);
    },
    citys() {
      if (!this.change_person.province) return [];
      let index = this.provinces.indexOf(this.change_person.province);
      if (index == -1) return [];
      return this.config.citys[index].sub.map((item) => item.name);
    },
  },
  methods: {
    initData(type) {
      let dataList = [];
      for (let key in this.dict) {
        let temp = {
          key,
          label: this.dict[key].label,
          contentType: this.dict[key].type,
          method: this.dict[key].method,
          option: this.dict[key].option || [],
        };
        dataList.push(temp);
      }
      this.dataChangedList = dataList;
      this.dataChangeList = dataList.map((v) => {
        if (v.key.includes("can_")) {
          v = {
            ...v,
            key: v.key.replace("can_", ""),
            label: v.label.replace("剩余", ""),
          };
        }
        return v;
      });

      if (this[type].area) {
        this.getRMM(0, "RMM", type, true);
      }
      if (this[type].RSM) {
        this.getRMM(this[type].RMM, "RSM", type, true);
      }
      if (this[type].DSM) {
        this.getRMM(this[type].RSM, "DSM", type, true);
      }
      this.getAreaList();
    },
    getAreaList() {
      this.$axios
        .get(this.$urls.hospital.areaList)
        .then((res) => {
          this.config.AreaList = res.data.map((v) => {
            return { label: v.area };
          });
        })
        .catch(() => {});
    },
    //获取AMM RSM DSM
    getRMM(e, key, type, status = false) {
      if (!status) {
        this[type].DSM = "";
        this.config[`${type}DSMList`] = {};
        if (!status && key == "RMM") {
          this[type].RMM = "";
          this[type].RSM = "";
          this.config[`${type}RMMList`] = {};
          this.config[`${type}RSMList`] = {};
        }
        if (!status && key == "RSM") {
          this[type].RSM = "";
          this.config[`${type}RSMList`] = {};
        }
      }

      let url = this.$tools.getURL(this.$urls.hospital.managerList, {
        id: e,
        area: this[type].area,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.config[`${type}${key}List`] = res.data.map((v) => {
            return { id: v.id, label: v.name };
          });
        })
        .catch(() => {});
    },
    //获取报名信息
    getSignInfo(type) {
      if (!/^1\d{10}$/g.test(this[type].phone)) return;
      let currLoading = this.$tips.loading();
      let url = this.$tools.getURL(this.$urls.hospital.detail_by_phone_new, {
        phone: this[type].phone,
        type: type == "change_person" ? 1 : 0, //type 0查被变更人 1查变更人
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.$tools.setStorage(`old${type}`, res.data);
          this[type] = Object.assign({}, { ...res.data });
          // this.oldNum = {
          //   cohosts_count: 0,
          //   guest_count: 0,
          //   speaker_count: 0,
          // };
          if (type == "change_person") {
            //变更人  基础权益  最小值
            this.changeMin = {
              cohosts_count: res.data.cohosts_count,
              guest_count: res.data.guest_count,
              speaker_count: res.data.speaker_count,
            };
            this.oldNum = {
              ...this.changeMin,
            };
            if (this.changed_person.phone?.length == 11) {
              this.changed_person =
                this.$tools.getStorage("oldchanged_person") || {};
            }
          } else {
            //被变更人 基础权益
            this.changedMax = {
              cohosts_count: res.data.can_cohosts_count,
              guest_count: res.data.can_guest_count,
              speaker_count: res.data.can_speaker_count,
            };
            if (this.change_person.phone?.length == 11) {
              this.change_person =
                this.$tools.getStorage("oldchange_person") || {};

              this.oldNum = {
                cohosts_count: this.change_person.cohosts_count,
                guest_count: this.change_person.guest_count,
                speaker_count: this.change_person.speaker_count,
              };
            }
          }
          for (const key in this.changedMax) {
            let content = this.changedMax[key] + this.changeMin[key];
            this.changedMax[key] = content >= 3 ? 3 : content; //最大值
          }
          this.initData(type);
          currLoading.close();
          // console.log("this. :>> ", this.oldNum);
        })
        .catch((err) => {
          console.error(err);
          this.oldNum = {
            cohosts_count: 0,
            guest_count: 0,
            speaker_count: 0,
          };
          let phone = this[type].phone;
          this[type] = {
            phone,
          };
          if (
            type == "change_person" &&
            this.changed_person.phone?.length == 11
          ) {
            //变更人  原始值
            this.changed_person =
              this.$tools.getStorage("oldchanged_person") || {};
          } else if (
            type == "changed_person" &&
            this.change_person.phone?.length == 11
          ) {
            //被变更人 原始值
            this.change_person =
              this.$tools.getStorage("oldchange_person") || {};
          }
          currLoading.close();
          this.$tools.setStorage(`old${type}`, {});
          // console.log("this. :>> ", this.oldNum);
        });
    },

    //获取医院
    getHospital(type) {
      let url = this.$tools.getURL(this.$urls.hospital.hospitalList, {
        province: this[type].province,
        city: this[type].city,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.config.hospitalList = res.data.map((v) => {
            return {
              label: v.hospital,
              hospital_code: v.hospital_code,
              department: v.department,
            };
          });
        })
        .catch(() => {});
    },
    getSelectOption(key, type) {
      switch (key) {
        case "can_cohosts_count":
        case "can_guest_count":
        case "can_speaker_count":
          return this.config.count;
        case "province":
          return this.provinces;
        case "city":
          return this.citys;
        case "area":
          return this.config.AreaList;
        case "RMM":
          return this.config[`${type}RMMList`];
        case "RSM":
          return this.config[`${type}RSMList`];
        case "DSM":
          return this.config[`${type}DSMList`];
        case "hospital":
          return this.config.hospitalList;
        case "department":
          return this.config.departmentList;
        case "Ta":
          return this.config.TaList;
      }
    },
    changeInput(item, type) {
      switch (item.key) {
        case "phone":
          this[type][item.key] = this[type][item.key].replace(/\D/g, "");
          if (this[type][item.key].length < 11) {
            let phone = this[type].phone;
            this[type] = {
              phone,
            };
          }

          if (this.getInfoTimer) {
            clearTimeout(this.getInfoTimer);
            this.getInfoTimer = null;
          }
          this.getInfoTimer = setTimeout(() => {
            this.getSignInfo(type);
          }, 500);
          break;
        case "sale_phone":
          this[type][item.key] = this[type][item.key].replace(/\D/g, "");

          break;
      }
    },
    changeValue(item, type) {
      switch (item.key) {
        case "area":
          this.getRMM(0, "RMM", type);
          break;
        case "RMM":
          this.getRMM(this[type][item.key], "RSM", type);
          break;
        case "RSM":
          this.getRMM(this[type][item.key], "DSM", type);
          break;
        case "province":
          this.changeProvince(type);
          break;
        case "city":
          this.changeCity(type);
          break;
        case "hospital":
          this.changeHospital(type);
          break;
      }
    },
    changeHospital(type) {
      this.config.hospitalList.filter((v) => {
        if (v.label == this[type].hospital) {
          this[type].hospital_code = v.hospital_code;
          this.config.departmentList = v.department;
        }
      });
      this[type].department = "";
    },
    changeProvince(type) {
      this[type].city = "";
      this[type].hospital = "";
      this[type].hospital_code = "";
      this[type].department = "";
    },
    changeCity(type) {
      //筛选医院
      this[type].hospital = "";
      this[type].hospital_code = "";
      this[type].department = "";
      this.getHospital(type);
    },

    changeNumber(e, key) {
      // console.log("this.oldNum :>> ", this.oldNum);
      if (e > this.oldNum[key]) {
        this.changed_person[`can_${key}`]--;
      } else if (e < this.oldNum[key]) {
        this.changed_person[`can_${key}`]++;
      }
      this.oldNum[key] = e;
    },
    checkData(type) {
      for (let index = 0; index < this.dataChangeList.length; index++) {
        const element = this.dataChangeList[index];
        switch (element.key) {
          case "RMM":
          case "area":
          case "RSM":
          case "DSM":
          case "province":
          case "city":
          case "department":
            if (!this[type]?.[element.key]) {
              this.$tips.error({ text: `请选择${element.label}` });
              this[type][element.key] = "";
              return false;
            }
            break;
          case "cohosts_count":
          case "guest_count":
          case "speaker_count":
            if (this[type]?.[element.key] == undefined) {
              this[type][element.key] = 0;
            }
            break;
          case "name":
          case "sale_name":
          case "Ta":
            if (!this[type]?.[element.key]) {
              this.$tips.error({ text: `请输入${element.label}` });
              this[type][element.key] = "";
              return false;
            }
            break;
          case "code": //12位长度限制
            if (
              !this[type]?.[element.key] &&
              !/^\d{2,12}$/.test(this[type]?.[element.key])
            ) {
              this[type][element.key] = "";
              return this.$tips.error({ text: `请输入2-12位HCP医生编码` });
            }

            break;
          case "phone":
          case "sale_phone":
            if (!/^1\d{10}$/g.test(this[type]?.[element.key])) {
              this[type][element.key] = "";
              return this.$tips.error({
                text: `请输入正确的${element.label}`,
              });
            }
            break;
        }
      }
      if (
        this.change_person.cohosts_count +
          this.change_person.speaker_count +
          this.change_person.guest_count >
        3
      ) {
        this.$tips.error({ text: "本期活动每位专家最多参与3次权益，请调整" });
        return false;
      }
      return true;
    },
    //提交医生信息
    doCommit() {
      if (!this.changed_person.id)
        return this.$tips.error({ text: `请输入被变更人电话` });
      if (!this.checkData("change_person") || this.loading) return;
      this.$tools.setStorage(`oldchange_person`, {});
      this.$tools.setStorage(`oldchanged_person`, {});
      this.doChange();
    },

    async doChange() {
      this.loading = true;
      let currLoading = this.$tips.loading();
      this.$axios
        .post(this.$urls.hospital.changePerson, {
          change_person: { ...this.change_person },
          changed_person: { ...this.changed_person },
        })
        .then(() => {
          this.loading = false;
          this.$tips.success({ text: "提交成功" });
          currLoading.close();
          setTimeout(() => {
            window.location.href = `${this.$config.report}/manage/#/navigation`;
          }, 500);
          // window.open(`${this.$config.report}/manage/#/navigation`);
        })
        .catch(() => {
          this.loading = false;
          setTimeout(() => {
            // this.$tips.success({ text: "提交成功" });
            currLoading.close();
          }, 500);
        });
    },
  },
};
</script>
<style>
.hospitalInfo_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
  background: #f3f5f7;
  padding: 16px;
}
.hospitalInfo_content_block {
  display: flex;
}
.hospitalInfo_head {
  background: #fff;
  border-radius: 16px;
  min-height: 80px;
  font-size: 24px;
  font-weight: bold;
  padding-left: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.hospitalInfo_content {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
}
.hospitalInfo_content:last-child {
  margin-left: 12.8px;
}
.hospitalInfo_main {
  flex: 1 0 0;
}
.hospitalInfo_item {
  background: #fff;
  border-radius: 16px;
  min-height: 80px;
  font-size: 18px;
  padding: 16px;
  display: flex;
  margin-bottom: 16px;
}
.hospitalInfo_item_key {
  margin-bottom: 16px;
}
.hospitalInfo_container .el-select {
  width: 100%;
}
.hospitalInfo_foot {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hospitalInfo_foot .submit {
  width: 80%;
}
.hospitalInfo_audit_reason {
  background: #fff;
  color: red;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding: 16px;
  padding-left: 24px;
  word-break: break-all;
}
.hospitalInfo_container .el-input-number {
  width: 100%;
}
.el-input .el-input__count .el-input__count-inner {
  padding: 0 !important;
  font-size: 10px !important;
}
.hospitalInfo_item_value_input .el-input__suffix {
  display: none !important;
}
.hospitalInfo_container .el-input {
  display: flex;
}
.hospitalInfo_container .el-select__wrapper,
.hospitalInfo_container .el-input__wrapper {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: 1px solid #ccc !important;
}
</style>